import { darken } from 'polished'
import { mediaWidthTemplates, useMediaTemplates } from './mediaWidths'

// BRAND GUIDELINE
const satori = {
  midnight: '#101151',
  ocean: '#002CD0',
  petal: '#FFC4FF',
  amethyst: '#A06BC1',
  coral: '#FF6043',
  mint: '#C4FABD',
  cloud: '#F7F5F4',
  studio: '#4667E3',
  spearmint: '#75C672',
  sonar: '#AF64DC',
  shopdrop: '#FF6A50',
}

const alpha = {
  '1': 'FF',
  '.95': 'F2',
  '.9': 'E6',
  '.85': 'D9',
  '.8': 'CC',
  '.75': 'BF',
  '.7': 'B3',
  '.65': 'A6',
  '.6': '99',
  '.55': '8C',
  '.5': '80',
  '.45': '73',
  '.4': '66',
  '.35': '59',
  '.3': '4D',
  '.25': '40',
  '.2': '33',
  '.15': '26',
  '.1': '1A',
  '.05': '0D',
  '.0': '00',
}

const colors = {
  ...satori,
  black: '#000',
  white: '#fff',
  primary: satori.midnight, // midnight, ocean, petal
  primaryHover: '#27286B',
  secondary: satori.cloud, // cloud, mint
  background: satori.cloud,
  text: satori.midnight,
  textLight: satori.midnight + alpha['.9'],
  textExtraLight: satori.midnight + alpha['.7'],
}

const buttons = {
  borderRadius: '12px',
  cursor: 'pointer',
  fontSize: '18px',
  fontWeight: 400,
  padding: '17px 24px 17px 24px',
  // with fill
  primary: {
    default: {
      color: colors.white,
      bg: colors.primary,
      border: 'none',
    },
    hover: {
      color: colors.white,
      bg: colors.primaryHover,
      border: 'none',
    },
    active: {
      color: darken(0.5, colors.white),
      bg: colors.primary,
      border: 'none',
    },
    disabled: {
      color: colors.white,
      bg: colors.primary + alpha['.2'],
      border: 'none',
    },
  },
  // with outline
  secondary: {
    default: {
      color: colors.primary,
      bg: 'transparent',
      border: `1px solid ${colors.primary}`,
    },
    hover: {
      color: colors.white,
      bg: colors.primaryHover,
      border: `1px solid ${colors.primaryHover}`,
    },
    active: {
      color: darken(0.5, colors.white),
      bg: colors.primary,
      border: `1px solid ${colors.primary}`,
    },
    disabled: {
      color: colors.primary + alpha['.6'],
      bg: 'transparent',
      border: `1px solid ${colors.primary + alpha['.6']}`,
    },
  },
}

export const UITheme = {
  colors,
  alpha,
  mediaWidth: mediaWidthTemplates,
  useMediaWidth: useMediaTemplates,
  fontFamily: `"Founders Grotesk", Archivo, ui-sans-serif, system-ui, -apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  fontSize: '14px',
  lineHeight: '20px',
  shadows: {
    card: '0 0 4px rgba(0, 0, 0, .125)',
  },
  // rebass variants
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [5, 6, 7],
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
  },
  variants: {
    avatar: {
      width: 'avatar',
      height: 'avatar',
      borderRadius: 'circle',
    },
    card: {
      p: 2,
      bg: 'background',
      boxShadow: 'card',
    },
    link: {
      color: 'primary',
    },
    nav: {
      fontSize: 1,
      fontWeight: 'bold',
      display: 'inline-block',
      p: 2,
      color: 'inherit',
      textDecoration: 'none',
      ':hover,:focus,.active': {
        color: 'primary',
      },
    },
  },
  buttons,
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
  },
}
