import ReactGA from 'react-ga4'

const analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
const isProduction = process.env.NODE_ENV === 'production'
const isAnalyticsEnabled = analyticsId && isProduction

if (isAnalyticsEnabled) {
  ReactGA.initialize(analyticsId)
}

export function pageView(page: string) {
  if (isAnalyticsEnabled) {
    ReactGA.send({ hitType: 'pageview', page })
  }
}
