import { useTheme } from 'styled-components'
import useMedia from 'use-media'

export function useGetIsMobileScreenSize() {
  const theme: any = useTheme()
  const upToLarger = useMedia(theme.useMediaWidth.upToLarger)
  const upToLarge = useMedia(theme.useMediaWidth.upToLarge)
  const upToMedium = useMedia(theme.useMediaWidth.upToMedium)
  const upToSmall = useMedia(theme.useMediaWidth.upToSmall)
  const upToExtraSmall = useMedia(theme.useMediaWidth.upToExtraSmall)

  return { upToLarge, upToMedium, upToSmall, upToExtraSmall, upToLarger }
}
