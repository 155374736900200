import { Button } from 'rebass/styled-components'
import styled from 'styled-components'

export const Base = styled(Button)<{
  width?: string
  margin?: string
  height?: string
  maxHeight?: string
  minHeight?: string
  minWidth?: string
  padding?: string
  fontSize?: string
}>`
  cursor: ${({ theme }) => theme.buttons.cursor};
  width: ${({ width }) => width ?? 'auto'};
  min-width: ${({ minWidth }) => minWidth ?? '146px'};
  height: ${({ height }) => height ?? 'none'};
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight};` : '')}
  min-height: ${({ minHeight }) => minHeight ?? '0'};
  margin: ${({ margin }) => margin ?? '0'};
  border: solid 1px transparent;
  border-radius: ${({ theme }) => theme.buttons.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme, fontSize }) => fontSize ?? theme.buttons.fontSize};
  font-weight: ${({ theme }) => theme.buttons.fontWeight};
  font-style: normal;
  padding: ${({ theme, padding }) => padding ?? theme.buttons.padding};
  text-decoration: none;
  &[disabled] {
    background-color: ${({ theme }) => `${theme.colors.background} !important`};
    border-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }
`

export const Button1 = styled(Base)`
  color: ${({ theme }) => theme.buttons.primary.default.color};
  background-color: ${({ theme }) => theme.buttons.primary.default.bg};
  border: ${({ theme }) => theme.buttons.primary.default.border};

  &:hover {
    color: ${({ theme }) => theme.buttons.primary.hover.color};
    background-color: ${({ theme }) => theme.buttons.primary.hover.bg};
    border: ${({ theme }) => theme.buttons.primary.hover.border};
  }
  &:active,
  &:focus {
    outline: none;
    color: ${({ theme }) => theme.buttons.primary.active.color};
    background-color: ${({ theme }) => theme.buttons.primary.active.bg};
    border: ${({ theme }) => theme.buttons.primary.active.border};
  }
  &[disabled] {
    cursor: not-allowed;
    color: ${({ theme }) => theme.buttons.primary.disabled.color};
    background-color: ${({ theme }) => `${theme.buttons.primary.disabled.bg} !important`};
    border: ${({ theme }) => theme.buttons.primary.disabled.border};
  }
`

export const Button2 = styled(Base)`
  color: ${({ theme }) => theme.buttons.secondary.default.color};
  background-color: ${({ theme }) => theme.buttons.secondary.default.bg};
  border: ${({ theme }) => theme.buttons.secondary.default.border};

  &:hover {
    color: ${({ theme }) => theme.buttons.secondary.hover.color};
    background-color: ${({ theme }) => theme.buttons.secondary.hover.bg};
    border: ${({ theme }) => theme.buttons.secondary.hover.border};
  }
  &:active,
  &:focus {
    outline: none;
    color: ${({ theme }) => theme.buttons.secondary.active.color};
    background-color: ${({ theme }) => theme.buttons.secondary.active.bg};
    border: ${({ theme }) => theme.buttons.secondary.active.border};
  }
  &[disabled] {
    cursor: not-allowed;
    color: ${({ theme }) => theme.buttons.secondary.disabled.color};
    background-color: ${({ theme }) => `${theme.buttons.secondary.disabled.bg} !important`};
    border: ${({ theme }) => theme.buttons.secondary.disabled.border};
  }
`

export const Button1ExternalLink = styled(Button1).attrs({
  as: 'a',
})`
  text-decoration: none;
`

export const Button2ExternalLink = styled(Button2).attrs({
  as: 'a',
})`
  text-decoration: none;
`
