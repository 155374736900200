import { VF } from './common/Layout'
import ProductDetail from './ProductDetail'
import { productDescriptionsCreator, productDescriptionsDev } from './content/productdescriptions'

export interface ValuePropsProps {
  isCreator: boolean
}

function ProductDetails({ isCreator }: ValuePropsProps) {
  function listProps() {
    return isCreator ? productDescriptionsCreator : productDescriptionsDev
  }

  return (
    <VF>
      {listProps().map((value, i) => (
        <ProductDetail key={i} {...{ ...value, index: i }} id={value.id}></ProductDetail>
      ))}
    </VF>
  )
}

export default ProductDetails
