import styled, { useTheme } from 'styled-components'
import { useGetIsMobileScreenSize } from '../../styles/useGetIsMobileScreenSize'
import { NavbarMenuLink } from '../common/Link'
import { HF, VF } from '../common/Layout'
import { ChevronBottom } from '../icons/Chevron'
import { useState } from 'react'
import { Shopdrop, Spearmint, Sonar, Studio } from '../logos/Satori'
import { HashLink } from 'react-router-hash-link'
import { Button1 } from './Button'

export const StyledMenu = styled.nav<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${({ theme }) => theme.colors.white};
  backdrop-filter: blur(5px);
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  text-align: left;
  padding: 6rem 2rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  height: 100vh;
  z-index: -1;
  overflow: scroll-y;
  box-sizing: content-box;
`

interface MenuProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const scaleFactor = '24px'
export const Links = styled(VF)`
  &:first-child {
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const LinkWrapper = styled(HF)`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
`
export const DropDownMenuItem = styled(HF)`
  font-size: 18px;
  margin: 0 8px;
  padding: 10px 20px 12px;
  color: ${({ theme }) => theme.colors.primary};
`
export const LogoWrapper = styled(HF)`
  margin-right: 0px;
  width: ${scaleFactor};
  height: ${scaleFactor};
  justify-content: center;
  align-items: center;
`

export const Indent = styled(HF)`
  width: 24px;
`

export const IndentedLinks = styled(VF)<{ open: boolean }>`
  &: * {
    opacity: ${({ open }) => (open ? '1' : '0')};
    transition: opacity 0.7s ease-in-out;
  }

  width: 100%;
`

export const StyledHashLink = styled(HashLink)`
  font-size: 18px;
  margin: 0 8px;
  padding: 10px 20px 12px;
  color: ${({ theme }) => theme.colors.primary};
`

function Menu({ open, setOpen }: MenuProps) {
  const { upToExtraSmall, upToMedium } = useGetIsMobileScreenSize()
  const theme: any = useTheme()
  const responsivePadding = theme.buttons.padding
  const responsiveFontSize = upToExtraSmall ? '14px' : '18px'

  const [openProducts, setOpenProducts] = useState(false)
  const [openResources, setOpenResources] = useState(false)

  function handleProducts(e: any) {
    e.stopPropagation()
    setOpenProducts(!openProducts)
  }

  function handleResources(e: any) {
    e.stopPropagation()
    setOpenResources(!openResources)
  }

  return (
    <StyledMenu open={open} onClick={() => setOpen(!open)}>
      <Links align={'center'} justify={'center'} width="100%">
        <LinkWrapper onClick={handleProducts}>
          <LogoWrapper>
            <ChevronBottom height="24" />
          </LogoWrapper>
          <DropDownMenuItem>Products</DropDownMenuItem>
        </LinkWrapper>
        {openProducts && (
          <IndentedLinks open={openProducts}>
            <LinkWrapper>
              <Indent />
              <LogoWrapper>
                <Studio height="24" />
              </LogoWrapper>
              <StyledHashLink smooth to="#creator-studio">
                NFT Creator Studio
              </StyledHashLink>{' '}
            </LinkWrapper>
            <LinkWrapper>
              <Indent />
              <LogoWrapper>
                <Spearmint height="24" />
              </LogoWrapper>
              <StyledHashLink smooth to="#spearmint">
                NFT Minting API
              </StyledHashLink>{' '}
            </LinkWrapper>
            <LinkWrapper>
              <Indent />
              <LogoWrapper>
                <Shopdrop height="24" />
              </LogoWrapper>
              <StyledHashLink smooth to="#shopdrop">
                NFT E-commerce Tool
              </StyledHashLink>{' '}
            </LinkWrapper>
            <LinkWrapper>
              <Indent />
              <LogoWrapper>
                <Sonar height="24" />
              </LogoWrapper>
              <StyledHashLink smooth to="#distribution">
                NFT Distribution tool
              </StyledHashLink>{' '}
            </LinkWrapper>
          </IndentedLinks>
        )}

        <LinkWrapper onClick={handleResources}>
          <LogoWrapper>
            <ChevronBottom height="24" />
          </LogoWrapper>
          <DropDownMenuItem>Resources</DropDownMenuItem>
        </LinkWrapper>

        {openResources && (
          <IndentedLinks open={openResources}>
            <LinkWrapper>
              <Indent />
              <LogoWrapper></LogoWrapper>
              <NavbarMenuLink to={{ pathname: '/case-studies/0' }} fontSize={'18px'}>
                Case Studies
              </NavbarMenuLink>
            </LinkWrapper>
            <LinkWrapper>
              <Indent />
              <LogoWrapper></LogoWrapper>
              <NavbarMenuLink to={{ pathname: 'https://docs.satori.art' }} target="_blank" fontSize={'18px'}>
                Docs
              </NavbarMenuLink>
            </LinkWrapper>
            {/* <LinkWrapper>
              <Indent />
              <LogoWrapper></LogoWrapper>
              <NavbarMenuLink to={{ pathname: 'https://docs.satori.art' }} target="_blank" fontSize={'18px'}>
                Education Hub
              </NavbarMenuLink>
            </LinkWrapper> */}
          </IndentedLinks>
        )}

        <LinkWrapper>
          <LogoWrapper></LogoWrapper>
          <StyledHashLink smooth to="#contact">
            Contact Us
          </StyledHashLink>{' '}
        </LinkWrapper>

        <LinkWrapper>
          <LogoWrapper></LogoWrapper>
          <Button1
            padding={responsivePadding}
            fontSize={responsiveFontSize}
            onClick={() => {
              window.location.href = 'https://studio.satori.art'
            }}
          >
            Create NFT
          </Button1>
        </LinkWrapper>
      </Links>
    </StyledMenu>
  )
}

export default Menu
