import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { HF, VF } from './common/Layout'
import { Button1 } from './common/Button'
import { Description } from './common/Typography'
import axios from 'axios'
import LoadingSpinner from './common/LoadingSpinner'

const Response = styled(Description)`
  margin-bottom: 8px;
  font-weight: bold;
  width: 100%;
`

const Wrapper = styled(HF)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.primary + theme.alpha['.05']};
  padding: 8px;
  border-radius: 12px;
  width: 100%;
`

const formStyles = css`
  padding: 18px 24px;
  font-size: 18px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  background: transparent;
  border-radius: 12px;
  margin-right: 0 !important;
`

const Input = styled.input`
  ${formStyles}
  width: 100%;
  flex-grow: 1;
  margin-right: 8px !important;

  &:active {
    border-color: transparent;
  }
`

const SubmitButton = styled.input`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.buttons.fontSize};
  font-weight: ${({ theme }) => theme.buttons.fontWeight};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.buttons.padding};
  border-radius: ${({ theme }) => theme.buttons.borderRadius};
  border: solid 1px transparent;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${({ theme }) => theme.buttons.padding};
  padding-right: ${({ theme }) => theme.buttons.padding};
`

const ButtonContainer = styled(HF)`
  width: 100%;
`

const Heading = styled(Description)`
  font-weight: bold;
`

function NewsletterForm() {
  const GOOGLE_FORM_EMAIL_ID = process.env.REACT_APP_NEWSLETTER_GOOGLE_FORM_EMAIL_ID as string
  const GOOGLE_FORM_ACTION_URL = process.env.REACT_APP_NEWSLETTER_GOOGLE_FORM_ACTION_URL as string
  const [email, setEmail] = useState('')
  const [isRequesting, setIsRequesting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState<string | null>(null)
  const formRef = useRef<HTMLFormElement>(null)

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setIsRequesting(true)

    const formData = new FormData()
    formData.append(GOOGLE_FORM_EMAIL_ID, email)

    axios
      .post(GOOGLE_FORM_ACTION_URL, formData)
      .then(() => {
        setEmail('')
        if (formRef && formRef.current) {
          formRef.current.reset()
        }
        setIsRequesting(false)
        setIsSuccess(true)
      })
      .catch((e: any) => {
        setIsRequesting(false)
        console.log(e.message)

        // Assume it's a CORS error. The POST request still goes through.
        if (e.message === 'Network Error') {
          setIsSuccess(true)
        }

        setIsError(e.message)
      })
  }

  function handleReset() {
    setIsError(null)
  }

  function showForm() {
    return isSuccess ? (
      <VF align="center" justify="center">
        <Response textAlign="center">Thank You!</Response>
      </VF>
    ) : isError ? (
      <VF align="start" justify="center">
        <Response textAlign="center">Uh Oh. There was an error with your submission: {isError}</Response>
        <ButtonContainer align="center" justify="center">
          <Button1 onClick={handleReset}>Try Again</Button1>
        </ButtonContainer>
      </VF>
    ) : (
      <form ref={formRef} style={{ width: '100%' }} onSubmit={handleSubmit}>
        <HF align="center" justify="center" width={'100%'}>
          {isRequesting ? (
            <LoadingSpinner />
          ) : (
            <Wrapper align="center" justify="center">
              <Input
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <SubmitButton type="submit" value="Submit" />
            </Wrapper>
          )}
        </HF>
      </form>
    )
  }

  return (
    <VF margin={'32px 0 0'} width={'100%'}>
      <Heading>Sign up for our newsletter</Heading>
      {showForm()}
    </VF>
  )
}

export default NewsletterForm
