import styled from 'styled-components'
import { VF, HF } from './common/Layout'
import { DataLabel, Description } from './common/Typography'

interface ProductsUsedProps {
  products: string[]
}

const Wrapper = styled(HF)`
  width: 100%;
  margin-top: 40px;
`

const Container = styled(VF)`
  margin-bottom: 32px;
`

const Products = styled(Description)`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
`

function ProductsUsed({ products }: ProductsUsedProps) {
  return (
    <Wrapper align="center" justify="center">
      <Container align="center" justify="center">
        {products.length > 0 ? (
          <>
            <DataLabel textAlign="center">Products Used</DataLabel>
            <Products textAlign="center">
              {products.reduce(
                (acc: string, val: string, i: number) => acc + (i <= products.length ? val + ', ' : val + ' '),
                ''
              )}
            </Products>
          </>
        ) : null}
      </Container>
    </Wrapper>
  )
}

export default ProductsUsed
