import { useThemeColors } from '../styles/useThemeColors'

export interface ProductNameProps {
  id: string
  content: string
}

function ProductColor({ id, content }: ProductNameProps) {
  const { spearmint, sonar, studio, shopdrop, primary } = useThemeColors()

  function getColor(id: string) {
    switch (id) {
      case 'Spearmint':
        return spearmint
      case 'Creator Studio':
        return studio
      case 'Sonar':
        return sonar
      case 'Shop Drop':
        return shopdrop
      default:
        return primary
    }
  }

  return <div style={{ color: getColor(id) }}>{content}</div>
}

export default ProductColor
