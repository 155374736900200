import { css } from 'styled-components'

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 744,
  upToMedium: 960,
  upToLarge: 1280,
  upToLarger: 1600,
  upToXtraLarge: 1800,
}

export const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(
  MEDIA_WIDTHS
).reduce((accumulator, size) => {
  ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `
  return accumulator
}, {}) as any

export const useMediaTemplates = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  return {
    ...accumulator,
    [size]: { maxWidth: `${(MEDIA_WIDTHS as any)[size]}px` },
  }
}, {})
