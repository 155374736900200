import styled from 'styled-components'
import { HF, VF } from './common/Layout'
import { Description } from './common/Typography'
import { Logo } from './logos/Satori'
import { InlineLink } from './common/Link'

const Credits = styled(Description)`
  margin-top: 24px;
`
function Footer() {
  return (
    <HF align="center" justify="center" padding={'0 0 61px'}>
      <VF align="center">
        <Logo height="48px" />
        <Credits>© 2021 Satori Technologies Inc.</Credits>
        <div>
          <InlineLink to={{ pathname: '/privacy-policy' }} style={{ marginRight: '24px' }}>
            Privacy Policy
          </InlineLink>
          <InlineLink to={{ pathname: '/terms-of-use' }}>Terms of Use</InlineLink>
        </div>
      </VF>
    </HF>
  )
}

export default Footer
