import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'

// Link
export const Base = styled(Link)<{
  fontFamily?: string
  width?: string
  height?: string
  padding?: string
  display?: string
  fontSize?: string
  textDecoration?: string
}>`
  font-family: ${({ theme, fontFamily }) => (fontFamily ? fontFamily : theme.fontFamily)};
  font-size: ${({ fontSize }) => fontSize ?? '18px'};
  font-weight: normal;
  text-decoration: ${({ textDecoration }) => textDecoration ?? 'none'};
  color: ${({ theme }) => theme.colors.primary};
  cursor: ${(props) => props.theme.buttons.cursor};
  width: ${({ width }) => width ?? 'auto'};
  ${({ height }) => (height ? `height: ${height};` : '')}
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  ${({ display }) => (display ? `display: ${display};` : '')}
  
  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }

  &.visited {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const InlineLink = styled(Base)`
  text-decoration: underline;
`

// NavLink
export const BaseNL = styled(NavLink)<{
  fontFamily?: string
  width?: string
  height?: string
  padding?: string
  margin?: string
  display?: string
  fontSize?: string
}>`
  font-family: ${({ theme, fontFamily }) => (fontFamily ? fontFamily : theme.fontFamily)};
  font-size: ${({ fontSize }) => fontSize ?? '18px'};
  font-weight: normal;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  cursor: ${(props) => props.theme.buttons.cursor};
  width: ${({ width }) => width ?? 'auto'};
  margin: ${({ margin }) => margin ?? '0 8px'};
  white-space: nowrap;
  text-align: center;
  ${({ height }) => (height ? `height: ${height};` : '')}
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  ${({ display }) => (display ? `display: ${display};` : '')}
  position: relative;
  background-color: transparent;
  padding: 10px 20px 12px;
  border-radius: 24px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary + theme.alpha['.05']};
  }

  &.active,
  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.colors.primary + theme.alpha['.05']};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: none;
    &.active {
      border-bottom: none;
      text-decoration: none;
    }
  `};
`

export const ButtonLink = styled(BaseNL)`
  background-color: ${(props) => props.theme.buttons.primary.default.bg};
  padding: ${({ padding }) => padding ?? '7px 24px'};
  color: ${({ theme }) => theme.colors.default.primary};
  border-radius: ${(props) => props.theme.buttons.borderRadius};
  font-weight: 500;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`

export const SecondaryButtonLink = styled(BaseNL)`
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 7px 24px;
  color: ${({ theme }) => theme.colors.text};
  border-radius: ${(props) => props.theme.buttons.primary.borderRadius};
  font-weight: 500;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
  }
`

export const NavbarLink = styled(BaseNL)`
  &:hover {
    opacity: 0.7;
  }
`

export const NavbarMenuLink = styled(NavbarLink)`
  &.active,
  &:focus {
    outline: none;
    background-color: transparent !important;
  }
`

export const NavbarMenuHashLink = styled(NavbarMenuLink)
