import styled from 'styled-components'
import { HF, VF } from './common/Layout'
import { Description } from './common/Typography'
import { reverseLayout, setFlexRowDirection } from '../utils/ReverseLayout'
import { ProductDescription } from './content/productdescriptions'
import { Shopdrop, Spearmint, Sonar, Studio } from './logos/Satori'
import ProductColor from './ProductName'
import NewsletterForm from './NewsletterForm'
import { ContactLink, DocsLink, ShopDropLink, StudioLink } from './LinkButtons'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import CreatorStudioVideo from './CreatorStudioVideo'

export interface ProductDetailProps extends ProductDescription {
  index: number
}

const Wrapper = styled(HF)`
  margin-bottom: 250px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    margin-bottom: 150px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-bottom: 120px;
  `};
`

const ElWrapper = styled(VF)`
  /* border: 1px solid cyan; */
`

const Image = styled.img`
  width: 100%;
`

const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 32px;
  `};
`

const ListItem = styled.li`
  line-height: 150%;
  color: ${({ theme }) => theme.colors.primary};
`

const Name = styled.span`
  font-size: 30px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.primary};
`

const CTAContainer = styled(HF)`
  margin: 0 0 32px;
  width: 100%;

  * {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`

function ProductDetail({ index, title, blurb, name, description, imgURL, features, CTA, id }: ProductDetailProps) {
  const isReversedLayout = reverseLayout(index)

  const { upToExtraSmall } = useGetIsMobileScreenSize()

  function getIcon(id: string) {
    switch (id) {
      case 'Spearmint':
        return <Spearmint />
      case 'Creator Studio':
        return <Studio />
      case 'Sonar':
        return <Sonar />
      case 'Shop Drop':
        return <Shopdrop />
      default:
        return <></>
    }
  }

  function getCTA([id, label = '', margin = '']: string[], i: number) {
    switch (id) {
      case 'NewsletterForm':
        return <NewsletterForm key={i} />
      case 'ContactLink':
        return <ContactLink label={label} margin={margin} key={i} />
      case 'DocsLink':
        return <DocsLink label={label} margin={margin} key={i} />
      case 'ShopifyLink':
        return <ShopDropLink key={i} />
      default:
        return <></>
    }
  }

  const d = {
    id: name,
    content: description,
  }

  function setFlexDirection(isReversedLayout: boolean) {
    return upToExtraSmall ? 'column' : setFlexRowDirection(isReversedLayout)
  }

  function isCreatorStudio(id: string) {
    return id === 'Creator Studio' ? true : false
  }

  return (
    <Wrapper direction={setFlexDirection(isReversedLayout)} id={id}>
      <ElWrapper justify={'start'} align={'start'} flex={upToExtraSmall ? 1 : 6}>
        <Image src={imgURL} alt={title}></Image>
      </ElWrapper>
      {upToExtraSmall ? null : <ElWrapper flex={1}></ElWrapper>}
      <ElWrapper justify={'start'} align={'start'} flex={upToExtraSmall ? 1 : 5}>
        <HF align="center" margin={'0 0 32px'}>
          <div style={{ marginRight: '16px' }}>{getIcon(name)}</div>
          <Name>{name}</Name>
        </HF>
        <HF margin={'0 0 32px'}>
          <Title>
            {title} <ProductColor {...d} />
          </Title>
        </HF>
        <HF margin={'0 0 32px'}>
          <Description>{blurb}</Description>
        </HF>

        {isCreatorStudio(name) ? (
          <>
            <HF margin={'0 0 64px'} width={'100%'}>
              <StudioLink />
            </HF>
            <HF className="cs-video" margin={'0 0 32px'} width={'100%'}>
              <CreatorStudioVideo />
            </HF>
          </>
        ) : null}

        <CTAContainer className="CTA_conatiner">{CTA.map((item: string[], i) => getCTA(item, i))}</CTAContainer>
        <VF>
          <ul>
            {' '}
            {features.map((item: string, i) => (
              <ListItem key={i}>{item}</ListItem>
            ))}
          </ul>
        </VF>
      </ElWrapper>
    </Wrapper>
  )
}

export default ProductDetail
