import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { UITheme } from './styles/theme'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={UITheme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
