import styled from 'styled-components'
import { VF, HF } from './common/Layout'

export type MetricProps = {
  metric: [string, string]
}

const Wrapper = styled(HF)`
  width: 100%;
`

const Container = styled(VF)`
  border-top: 1px solid rgba(16, 17, 80, 0.2);
  min-width: 267px;
  padding: 32px 0;
`

const Title = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
`
const Subtitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;

  letter-spacing: 0.07em;
  text-transform: uppercase;

  opacity: 0.5;
  margin-bottom: 0;
`

function Metric({ metric }: MetricProps) {
  return (
    <Wrapper justify={'center'} align={'center'}>
      <Container justify={'center'} align={'center'}>
        <Title>{metric[1]}</Title>
        <Subtitle>{metric[0]}</Subtitle>
      </Container>
    </Wrapper>
  )
}

export default Metric
