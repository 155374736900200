import { useThemeColors } from '../../styles/useThemeColors'

export function ChevronLeft({ height = '56px' }: { height?: string }) {
  const { primary } = useThemeColors()

  return (
    <svg width="56" height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M35 42L21 28L35 14" stroke={primary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}

export function ChevronRight({ height = '56px' }: { height?: string }) {
  const { primary } = useThemeColors()

  return (
    <svg width="56" height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M21 42L35 28L21 14" stroke={primary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}

export function ChevronBottom({ height = '56px' }: { height?: string }) {
  const { primary } = useThemeColors()

  return (
    <svg width="56" height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 21L28 35L42 21" stroke={primary} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}
