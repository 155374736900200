import styled from 'styled-components'
import { VF } from './common/Layout'
import { CaseStudyDesc } from './content/casestudies'
import { MinorHeading, Description } from './common/Typography'
import { InlineLink } from './common/Link'

interface CaseStudyDescriptionProps {
  descriptions: CaseStudyDesc[]
}

export const ProductFeaturesHeading = styled(MinorHeading)`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.primary};
`

export default function CaseStudyDescription({ descriptions }: CaseStudyDescriptionProps) {
  return (
    <VF>
      {descriptions.map((d, i) => (
        <VF key={i}>
          <ProductFeaturesHeading>{d.title}</ProductFeaturesHeading>
          {d.content.map((item, i) => (
            <div key={i}>
              <Description>{item.body}</Description>
              <Description>
                <InlineLink as="a" href={item.demoURL}>
                  Link to Demo
                </InlineLink>
              </Description>
            </div>
          ))}
        </VF>
      ))}
    </VF>
  )
}
