interface GenericProductDescription {
  id: string
  name: string
  description: string
  imgURL: string
}

export interface ProductDescription extends GenericProductDescription {
  title: string
  blurb: string
  blurbBold?: string
  features: string[]
  CTA: string[][]
}

export const productDescriptions = [
  {
    id: 'spearmint',
    name: 'Spearmint',
    description: 'Minting API',
    imgURL: '/spearmint.png',
  },
  {
    id: 'distribution',
    name: 'Sonar',
    description: 'Distribution Tool',
    imgURL: '/sonar.png',
  },
  {
    id: 'shopdrop',
    name: 'Shop Drop',
    description: 'Shopify Plug-in',
    imgURL: '/shopdrop.png',
  },
  {
    id: 'creator-studio',
    name: 'Creator Studio',
    description: 'Studio For Creators',
    imgURL: '/creator-studio.png',
  },
]

export const productDescriptionsCreator: ProductDescription[] = [
  {
    ...productDescriptions[3],
    title: 'No-code, self-service ',
    blurb:
      'A one-stop shop for minting, distributing, and managing NFT collections from an intuitive app dashboard, without a single line of code.',
    blurbBold: 'Sign up to be the first to build!',
    features: [
      'Drop NFTs on your own web properties',
      'Engage your entire fanbase in new ways',
      'Easiest claim experience in the NFT space',
    ],
    CTA: [['NewsletterForm', 'Your Email']],
  },
  {
    ...productDescriptions[0],
    title: "The World's Most Powerful ",
    blurb:
      "Satori's fast, easy minting API can generate hundreds of NFTs per minute, no blockchain coding skills required. Cost-effective & efficient minting on NEAR makes it possible to engage entire communities in a single drop.",
    features: [
      'Fast, low-cost minting & no crypto coding',
      'Users can claim NFTs in just 3 clicks',
      'Customize royalties natively in your NFTs',
    ],
    CTA: [
      ['ContactLink', 'Get in Touch'],
      ['DocsLink', 'Dive Into Docs'],
    ],
  },
  {
    ...productDescriptions[1],
    title: 'Seamless, direct-to-fan NFT ',
    blurb:
      'Create and mint thousands of NFTs from an intuitive dashboard – no coding necessary – and distribute NFTs directly to fans on customizable landing pages or through your social media accounts in just 3 clicks. ',
    features: [
      'Drop NFTs on your own custom pages',
      'No crypto required for fans to claim NFTs',
      'Integrate with any social media platform',
    ],
    CTA: [
      ['ContactLink', 'Get in Touch'],
      ['DocsLink', 'Get Started'],
    ],
  },
  {
    ...productDescriptions[2],
    title: 'Mint & sell NFTs directly via ',
    blurb:
      'Satori has partnered with Shopify to empower any US-based Shopify merchant to mint and sell NFT collections in their existing Shopify store! Sell in USD, manage from your dashboard: the easiest way to jump into NFTs',
    features: [
      'Generate new, perpetual revenue stream',
      'Increase sales & traffic in your own store',
      'Deal entirely in USD, no crypto required',
    ],
    CTA: [['ShopifyLink', 'Try The App']],
  },
]

export const productDescriptionsDev: ProductDescription[] = [
  {
    ...productDescriptions[0],
    title: "The World's Most Powerful ",
    blurb:
      "Satori's fast, easy minting API can generate hundreds of NFTs per minute, no blockchain coding skills required. Cost-effective & efficient minting on NEAR makes it possible to engage entire communities in a single drop.",
    features: [
      'Fast, low-cost minting & no crypto coding',
      'Users can claim NFTs in just 3 clicks',
      'Customize royalties natively in your NFTs',
    ],
    CTA: [
      ['ContactLink', 'Get in Touch'],
      ['DocsLink', 'Dive Into Docs'],
    ],
  },
  {
    ...productDescriptions[1],
    title: 'Seamless, direct-to-fan NFT ',
    blurb:
      'Create and mint thousands of NFTs from an intuitive dashboard – no coding necessary – and distribute NFTs directly to fans on customizable landing pages or through your social media accounts in just 3 clicks. ',
    features: [
      'Drop NFTs on your own custom pages',
      'No crypto required for fans to claim NFTs',
      'Integrate with any social media platform',
    ],
    CTA: [
      ['ContactLink', 'Get in Touch'],
      ['DocsLink', 'Get Started'],
    ],
  },
  {
    ...productDescriptions[2],
    title: 'Mint & sell NFTs directly via ',
    blurb:
      'Satori has partnered with Shopify to empower any US-based Shopify merchant to mint and sell NFT collections in their existing Shopify store! Sell in USD, manage from your dashboard: the easiest way to jump into NFTs',
    features: [
      'Generate new, perpetual revenue stream',
      'Increase sales & traffic in your own store',
      'Deal entirely in USD, no crypto required',
    ],
    CTA: [['ShopifyLink', 'Try The App']],
  },
  {
    ...productDescriptions[3],
    title: 'No-code, self-service ',
    blurb:
      'A one-stop shop for minting, distributing, and managing NFT collections from an intuitive app dashboard, without a single line of code.',
    blurbBold: 'Sign up to be the first to build!',
    features: [
      'Drop NFTs on your own web properties',
      'Engage your entire fanbase in new ways',
      'Easiest claim experience in the NFT space',
    ],
    CTA: [['NewsletterForm', 'Your Email']],
  },
]
