import styled from 'styled-components'
import { Content } from './common/Layout'

const Image = styled.img`
  width: 100%;
  max-width: 964px;
`

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 150px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: 120px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-bottom: 106px;
  `};

  background: transparent;
`

export default function ProductImage() {
  return (
    <Wrapper>
      <Content align={'center'}>
        <Image src="product.png" alt="satori product"></Image>
      </Content>
    </Wrapper>
  )
}
