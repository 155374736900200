import styled from 'styled-components'
import { BrandmarkVertical } from '../components/Brandmark'
import FormAndFooter from '../components/common/FormAndFooter'
import { HeroWrapper, ContentWrapper, Content, VF } from '../components/common/Layout'
import { Title, TitleSection, Description } from '../components/common/Typography'
import { InlineLink } from '../components/common/Link'

const Tagline = styled(Title)`
  margin-bottom: 40px;
`

const Bold = styled.span`
  font-weight: bold;
`

const Section = styled.section`
  margin-bottom: 40px;
`

const PPTitleSection = styled(TitleSection)`
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;

  margin-bottom: 24px;
`

const ContentGrid = styled(Content)`
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
`

function PrivacyPolicy() {
  return (
    <>
      <HeroWrapper>
        <Content align={'center'}>
          <div style={{ marginBottom: 64 }}>
            <BrandmarkVertical />
          </div>
          <Tagline>Website Terms Of Use</Tagline>
        </Content>
      </HeroWrapper>
      <ContentWrapper>
        <ContentGrid align={'start'}>
          <VF />
          <VF>
            <Section>
              <Description>Last Modified: February 16, 2022</Description>
            </Section>

            <Section>
              <PPTitleSection>Acceptance of the Terms of Use</PPTitleSection>
              <Description>
                These terms of use are entered into by and between you and Satori Technologies Inc. ("
                <Bold>Satori,</Bold>" "<Bold>we,</Bold>" or "<Bold>us</Bold>"). The following terms and conditions,
                together with any documents they expressly incorporate by reference (collectively, "
                <Bold>Terms of Use</Bold>"), govern your access to and use of satori.art, including any content,
                functionality and services offered on or through{' '}
                <InlineLink as="a" href="https://satori.art">
                  https://satori.art
                </InlineLink>{' '}
                (the "<Bold>Website</Bold>").
              </Description>
              <Description>
                Please read the Terms of Use carefully before you start to use the Website.{' '}
                <Bold>
                  By using the Website, you accept and agree to be bound and abide by these Terms of Use and our Privacy
                  Policy, found at{' '}
                  <InlineLink as="a" href="https://satori.art/privacy-policy" style={{ fontWeight: 'bold' }}>
                    https://satori.art/privacy-policy
                  </InlineLink>
                  , incorporated herein by reference.
                </Bold>{' '}
                If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the
                Website.
              </Description>
              <Description>
                This Website is offered and available to users who are 18 years of age or older. By using this Website,
                you represent and warrant that you are of legal age to form a binding contract with Satori. If you do
                not meet this requirement, you must not access or use the Website.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Changes to the Terms of Use</PPTitleSection>
              <Description>
                We may revise and update these Terms of Use from time to time in our sole discretion. All changes are
                effective immediately when we post them, and apply to all access to and use of the Website thereafter.
              </Description>
              <Description>
                Your continued use of the Website following the posting of revised Terms of Use means that you accept
                and agree to the changes. You are expected to check this page frequently so you are aware of any
                changes, as they are binding on you.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Accessing the Website and Account Security</PPTitleSection>
              <Description>
                We reserve the right to withdraw or amend this Website, and any service or material we provide on the
                Website, in our sole discretion without notice. We will not be liable if for any reason all or any part
                of the Website is unavailable at any time or for any period. From time to time, we may restrict access
                to some parts of the Website, or the entire Website, to users.
              </Description>
              <Description>You are responsible for both:</Description>
              <ul>
                <li>
                  <Description>Making all arrangements necessary for you to have access to the Website.</Description>
                </li>
                <li>
                  <Description>
                    Ensuring that all persons who access the Website through your internet connection are aware of these
                    Terms of Use and comply with them.
                  </Description>
                </li>
              </ul>
              <Description>
                To access some of the resources this Website offers, you may be asked to provide certain registration
                details or other information. It is a condition of your use of the Website that all the information you
                provide on the Website is correct, current, and complete. You agree that all information you provide,
                including, but not limited to, through the use of any interactive features on the Website, is governed
                by, and you consent to all actions we take with respect to your information consistent with, our Privacy
                Policy.
              </Description>
              <Description>
                If you choose, or are provided with, a user name, password, or any other piece of information as part of
                our security procedures, you must treat such information as confidential, and you must not disclose it
                to any other person or entity. You also acknowledge that your account is personal to you and agree not
                to provide any other person with access to this Website or portions of it using your user name,
                password, or other security information. You agree to notify us immediately of any unauthorized access
                to or use of your user name or password or any other breach of security. You should use particular
                caution when accessing your account from a public or shared computer so that others are not able to view
                or record your password or other personal information.
              </Description>
              <Description>
                We have the right to disable any user name, password, or other identifier, whether chosen by you or
                provided by us, at any time if, in our opinion, you have violated any provision of these Terms of Use.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Intellectual Property Rights</PPTitleSection>
              <Description>
                The Website and its entire contents, features, and functionality (including but not limited to all
                information, software, text, displays, images, video and audio, and the design, selection, and
                arrangement thereof), are owned by Satori, its licensors, or other providers of such material and are
                protected by United States and international copyright, trademark, patent, trade secret, and other
                intellectual property or proprietary rights laws.
              </Description>
              <Description>
                These Terms of Use permit you to use the Website for your personal, non-commercial use only. You must
                not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform,
                republish, download, store or transmit any of the material on our Website, except as follows:
              </Description>
              <ul>
                <li>
                  <Description>
                    Your computer may temporarily store copies of such materials in RAM incidental to your accessing and
                    viewing those materials.
                  </Description>
                </li>
                <li>
                  <Description>
                    You may store files that are automatically cached by your Web browser for display enhancement
                    purposes.
                  </Description>
                </li>
                <li>
                  <Description>
                    You may print one copy of a reasonable number of pages of the Website for your own personal,
                    non-commercial use and not for further reproduction, publication, or distribution.
                  </Description>
                </li>
              </ul>
              <Description>You must not:</Description>
              <ul>
                <li>
                  <Description>Modify copies of any materials from the Website.</Description>
                </li>
                <li>
                  <Description>
                    Delete or alter any copyright, trademark, or other proprietary rights notices from copies of
                    materials from the Website.
                  </Description>
                </li>
              </ul>
              <Description>
                You must not access or use for any commercial purposes any part of the Website or any services or
                materials available through the Website.
              </Description>
              <Description>
                If you print, copy, modify, download, or otherwise use or provide any other person with access to any
                part of the Website in breach of the Terms of Use, your right to use the Website will cease immediately,
                and you must, at our option, return or destroy any copies of the materials you have made. No right,
                title, or interest in or to the Website or any content on the Website is transferred to you, and all
                rights not expressly granted are reserved by Satori. Any use of the Website not expressly permitted by
                these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other
                laws.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Trademarks</PPTitleSection>
              <Description>
                The Satori name, the terms Satori, Creator Studio, Spearmint, Sonar, and Shop Drop, the Satori logo, and
                all related names, logos, product and service names, designs, and slogans are trademarks of Satori or
                its affiliates or licensors. You must not use such marks without the prior written permission of Satori.
                All other names, logos, product and service names, designs, and slogans on this Website are the
                trademarks of their respective owners.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Prohibited Uses</PPTitleSection>
              <Description>
                You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree
                not to use the Website:
              </Description>
              <ul>
                <li>
                  <Description>
                    In any way that violates any applicable federal, state, local, or international law or regulation
                    (including, without limitation, any laws regarding the export of data or software to and from the US
                    or other countries).
                  </Description>
                </li>
                <li>
                  <Description>
                    For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by
                    exposing them to inappropriate content, asking for personally identifiable information or otherwise.
                  </Description>
                </li>
                <li>
                  <Description>
                    To transmit, or procure the sending of, any advertising or promotional material, including any “junk
                    mail,” “chain letter,” “spam,” or any other similar solicitation.
                  </Description>
                </li>
                <li>
                  <Description>
                    To impersonate or attempt to impersonate Satori, a Satori employee, another user or any other person
                    or entity (including, without limitation, by using e-mail addresses associated with any of the
                    foregoing).
                  </Description>
                </li>
                <li>
                  <Description>
                    To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Website,
                    or which, as determined by us, may harm Satori or users of the Website, or expose them to liability.
                  </Description>
                </li>
              </ul>
              <Description>Additionally, you agree not to:</Description>
              <ul>
                <li>
                  <Description>
                    Use the Website in any manner that could disable, overburden, damage, or impair the Website or
                    interfere with any other party’s use of the Website, including their ability to engage in real time
                    activities through the Website.
                  </Description>
                </li>
                <li>
                  <Description>
                    Use any robot, spider, or other automatic device, process, or means to access the Website for any
                    purpose, including monitoring or copying any of the material on the Website.
                  </Description>
                </li>
                <li>
                  <Description>
                    Use any manual process to monitor or copy any of the material on the Website, or for any other
                    purpose not expressly authorized in these Terms of Use, without our prior written consent.
                  </Description>
                </li>
                <li>
                  <Description>
                    Use any device, software, or routine that interferes with the proper working of the Website.
                  </Description>
                </li>
                <li>
                  <Description>
                    Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or
                    technologically harmful.
                  </Description>
                </li>
                <li>
                  <Description>
                    Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website,
                    the server on which the Website is stored, or any server, computer, or database connected to the
                    Website.{' '}
                  </Description>
                </li>
                <li>
                  <Description>
                    Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.
                  </Description>
                </li>
                <li>
                  <Description>Otherwise attempt to interfere with the proper working of the Website.</Description>
                </li>
              </ul>
            </Section>

            <Section>
              <PPTitleSection>Reliance on Information Posted</PPTitleSection>
              <Description>
                The information presented on or through the Website is made available solely for general information
                purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance
                you place on such information is strictly at your own risk. We disclaim all liability and responsibility
                arising from any reliance placed on such materials by you or any other visitor to the Website, or by
                anyone who may be informed of any of its contents.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Changes to the Website</PPTitleSection>
              <Description>
                We may update the content on this Website from time to time, but its content is not necessarily complete
                or up-to-date. Any of the material on the Website may be out of date at any given time, and we are under
                no obligation to update such material.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Information About You and Your Visits to the Website</PPTitleSection>
              <Description>
                All information we collect on this Website is subject to our Privacy Policy. By using the Website, you
                consent to all actions taken by us with respect to your information in compliance with the Privacy
                Policy.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Links from the Website</PPTitleSection>
              <Description>
                If the Website contains links to other sites and resources provided by third parties, these links are
                provided for your convenience only. This includes links contained in advertisements, including banner
                advertisements and sponsored links. We have no control over the contents of those sites or resources,
                and accept no responsibility for them or for any loss or damage that may arise from your use of them. If
                you decide to access any of the third party websites linked to this Website, you do so entirely at your
                own risk and subject to the terms and conditions of use for such websites.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Disclaimer of Warranties</PPTitleSection>
              <Description>
                WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES,
                OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
                DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED
                THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
              </Description>
              <Description>
                YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR
                OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED
                ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
                NEITHER SATORI NOR ANY PERSON ASSOCIATED WITH SATORI MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
                TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT
                LIMITING THE FOREGOING, NEITHER SATORI NOR ANYONE ASSOCIATED WITH SATORI REPRESENTS OR WARRANTS THAT THE
                WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE,
                ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
                AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS
                OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              </Description>
              <Description>
                SATORI HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
                INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
                PARTICULAR PURPOSE.
              </Description>
              <Description>
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Limitation on Liability</PPTitleSection>
              <Description>
                TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL SATORI, ITS AFFILIATES OR THEIR LICENSORS,
                SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER
                ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
                WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, OR ANY SERVICES OR ITEMS
                OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
                INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
                SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
                SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
                BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
              </Description>
              <Description>
                THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Indemnification</PPTitleSection>
              <Description>
                You agree to defend, indemnify and hold harmless Satori, its affiliates, licensors, and service
                providers, and its and their respective officers, directors, employees, contractors, agents, licensors,
                suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards,
                losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to
                your violation of these Terms of Use or your use of the Website, including, but not limited to, any use
                of the Website’s content, services, and products other than as expressly authorized in these Terms of
                Use, or your use of any information obtained from the Website.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Governing Law and Jurisdiction</PPTitleSection>
              <Description>
                All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom
                or related thereto (in each case, including non-contractual disputes or claims), shall be governed by
                and construed in accordance with the internal laws of the State of Delaware without giving effect to any
                choice or conflict of law provision or rule (whether of the State of Delaware or any other
                jurisdiction).
              </Description>
              <Description>
                Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use, or the Website
                shall be instituted exclusively in the federal courts of the United States or the courts of the State of
                Delaware, in each case located in the City of Dover and County of Kent, although we retain the right to
                bring any suit, action, or proceeding against you for breach of these Terms of Use in your state or
                country of residence or any other relevant state or country. You waive any and all objections to the
                exercise of jurisdiction over you by such courts and to venue in such courts.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Arbitration</PPTitleSection>
              <Description>
                At Satori's sole discretion, it may require You to submit any disputes arising from these Terms of Use
                or use of the Website, including disputes arising from or concerning their interpretation, violation,
                invalidity, non-performance, or termination, to final and binding arbitration under the Rules of
                Arbitration of the American Arbitration Association applying Delaware law.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Waiver and Severability</PPTitleSection>
              <Description>
                No waiver of by Satori of any term or condition set forth in these Terms of Use shall be deemed a
                further or continuing waiver of such term or condition or a waiver of any other term or condition, and
                any failure of Satori to assert a right or provision under these Terms of Use shall not constitute a
                waiver of such right or provision.
              </Description>
              <Description>
                If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to
                be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to
                the minimum extent such that the remaining provisions of the Terms of Use will continue in full force
                and effect.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Entire Agreement</PPTitleSection>
              <Description>
                The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and Satori
                Technologies Inc. with respect to the Website and supersede all prior and contemporaneous
                understandings, agreements, representations, and warranties, both written and oral, with respect to the
                Website.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Your Comments and Concerns</PPTitleSection>
              <Description>
                This website is operated by Satori Technologies Inc., 3500 South Dupont Highway, Dover, Delaware 19901.
              </Description>
              <Description>
                All feedback, comments, requests for technical support and other communications relating to the Website
                should be directed to:{' '}
                <InlineLink as="a" href="mailto: info@satori.art">
                  info@satori.art
                </InlineLink>
                .
              </Description>
            </Section>
          </VF>
          <VF />
        </ContentGrid>
      </ContentWrapper>
      <FormAndFooter isFeedback />
    </>
  )
}

export default PrivacyPolicy
