import { useState, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { HF } from '../components/common/Layout'
import { Button1 } from './common/Button'
import { NavbarLink } from '../components/common/Link'
import { NavLink } from 'react-router-dom'
import { Logomark } from './logos/Satori'
import { HashLink } from 'react-router-hash-link'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import Burger from '../components/common/Burger'
import Menu from '../components/common/Menu'
import { useOnClickOutside } from '../hooks/useOnClickOutside'

export type NavbarProps = {
  className?: string
}

const HeaderContainer = styled(HF)`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  z-index: 100;
  background: transparent;
  backdrop-filter: blur(20px);
`

const HeaderContent = styled(HF)`
  padding: 40px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`    
    padding: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`    
    padding: 24px;
  `};
`

export const Links = styled(HF)`
  * {
    margin-right: 48px;

    &:last-of-type {
      margin-right: 0;
    }

    ${({ theme }) => theme.mediaWidth.upToMedium`    
      margin-right: 4px;

      &:last-of-type {
        margin-right: 0;
      }
    `};
  }
`

function Navbar({ className }: NavbarProps) {
  const { upToExtraSmall, upToMedium } = useGetIsMobileScreenSize()
  const theme: any = useTheme()

  const responsivePadding = upToExtraSmall ? '4px 12px 6px' : theme.buttons.padding
  const responsiveFontSize = upToExtraSmall ? '14px' : '18px'

  const [open, setOpen] = useState(false)
  const node = useRef<HTMLDivElement>(null)
  useOnClickOutside(node, () => setOpen(false))

  return (
    <HeaderContainer justify="center" className={className}>
      <HeaderContent>
        {/* LOGO */}
        <NavLink to="/">
          <Logomark height={upToExtraSmall ? 36 : upToMedium ? 40 : 48} />
        </NavLink>
        {upToMedium ? (
          <div ref={node}>
            <Burger open={open} setOpen={setOpen} />
            <Menu open={open} setOpen={setOpen} />
          </div>
        ) : (
          <>
            <HF align="center" justify="center">
              <Links align={'center'} justify={upToExtraSmall ? 'space-between' : 'flex-end'} width="100%">
                <NavbarLink
                  exact={true}
                  fontSize={upToExtraSmall ? '12px' : '18px'}
                  to={{ pathname: 'https://studio.satori.art/' }}
                  target="_blank"
                >
                  Creator Studio
                </NavbarLink>
                <NavbarLink fontSize={upToExtraSmall ? '12px' : '18px'} to={{ pathname: '/case-studies/0' }}>
                  Case Studies
                </NavbarLink>
                <NavbarLink
                  fontSize={upToExtraSmall ? '12px' : '18px'}
                  to={{ pathname: 'https://docs.satori.art' }}
                  target="_blank"
                >
                  Docs
                </NavbarLink>
                <NavbarLink
                  fontSize={upToExtraSmall ? '12px' : '18px'}
                  to={{ pathname: 'https://nfu.satori.art' }}
                  target="_blank"
                >
                  {'🇺🇦  Support Ukraine!'}
                </NavbarLink>
              </Links>
            </HF>
            <Button1
              padding={responsivePadding}
              fontSize={responsiveFontSize}
              onClick={() => {
                window.location.href = 'https://studio.satori.art'
              }}
            >
              Create NFT
            </Button1>
          </>
        )}
      </HeaderContent>
    </HeaderContainer>
  )
}

export default Navbar
