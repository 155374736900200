import styled from 'styled-components'
import CaseStudies from '../components/CaseStudies'
import CustomerSwitch from '../components/CustomerSwitch'
import Hero from '../components/Hero'
import ProductImage from '../components/ProductImage'
import bg from '../assets/header-blobs.png'
import FormAndFooter from '../components/common/FormAndFooter'

const BlobWrapper = styled.div`
  background: url(${bg}) no-repeat center bottom scroll;
  background-size: cover;

  ${({ theme }) => theme.mediaWidth.upToLarger`
    background: url(${bg}) no-repeat center center scroll;
    background-size: cover;
  `};
`

function Landing() {
  return (
    <>
      <BlobWrapper>
        <Hero />
        <ProductImage />
      </BlobWrapper>
      <CustomerSwitch />
      <CaseStudies />
      <FormAndFooter />
    </>
  )
}

export default Landing
