import styled from 'styled-components'
import { WeightTypes } from './types'

export enum TypographyColor {
  WHITE = 'white',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

function switchColor(color: TypographyColor | undefined, theme: any) {
  switch (color) {
    case TypographyColor.WHITE:
      return theme.colors.white
    case TypographyColor.PRIMARY:
      return theme.colors.primary
    case TypographyColor.SECONDARY:
      return theme.colors.secondary
    default:
      return theme.colors.text
  }
}

export const Typography = styled.span<{
  fontFamily?: string
  weight?: WeightTypes
  size?: string
  align?: string
  color?: TypographyColor
  lineHeight?: string
  margin?: string
  padding?: string
  wrap?: string
  width?: string
  overflow?: string
  textOverflow?: string
}>`
  font-family: ${({ theme, fontFamily }) => (fontFamily ? fontFamily : theme.fontFamily)};
  font-weight: ${({ weight }) => weight ?? 400};
  font-size: ${({ theme, size }) => (size ? `${size}` : theme.fontSize)};
  text-align: ${({ align }) => align ?? 'left'};
  color: ${({ color, theme }) => switchColor(color, theme)};
  line-height: ${({ theme, lineHeight }) => (lineHeight ? lineHeight : theme.lineHeight)};
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ overflow }) => (overflow ? `overflow: ${overflow};` : '')}
  ${({ textOverflow }) => (textOverflow ? `text-overflow: ${textOverflow};` : '')}
  ${({ wrap }) => (wrap ? `white-space: ${wrap};` : '')}
`

export const ErrorTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.petal};
`

export const WarningTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.coral};
`

// SATORI
export const Title = styled.h1<{
  textAlign?: string
}>`
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  font-weight: bold;
  font-size: 80px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.primary};

  margin-bottom: 40px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 64px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 38px;
  `};
`

export const TitleSection = styled.h2<{
  textAlign?: string
}>`
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 120%;
  margin-bottom: 64px;
  color: ${({ theme }) => theme.colors.primary};
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 56px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 40px;
  `};
`

export const SubTitleSection = styled.h3<{
  textAlign?: string
}>`
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.primary};
  width: 100%;
`

export const Description = styled.p<{
  textAlign?: string
}>`
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  opacity: 0.7;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 24px;
`

export const MinorHeading = styled.h4`
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.primary};
`

export const DataLabel = styled.dl<{
  textAlign?: string
}>`
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  opacity: 0.5;
  margin-bottom: 0;
`
