function CreatorStudioVideo() {
  return (
    <div style={{ position: 'relative', paddingBottom: '64.63195691202873%', width: '100%', height: 'auto' }}>
      <iframe
        title="Creator Studio video"
        src="https://www.loom.com/embed/38817b9582654c3ca08fd75423ece916"
        frameBorder="0"
        // webkitallowfullscreen
        // mozallowfullscreen
        allowFullScreen
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      ></iframe>
    </div>
  )
}

export default CreatorStudioVideo
