import { useParams } from 'react-router-dom'
import CaseStudyContent from '../components/CaseStudyContent'
import { caseStudies } from '../components/content/casestudies'
import CaseStudyCarousel from '../components/CaseStudyCarousel'
import FormAndFooter from '../components/common/FormAndFooter'

function CaseStudy() {
  const { id } = useParams<{ id: string }>()
  const content = getContent(id)

  function getContent(id: string) {
    return caseStudies.find((caseStudy: any) => caseStudy.id === id)
  }

  function renderContent(content: any | undefined) {
    if (content === undefined) {
      return <>404 Not Found</>
    } else
      return (
        <>
          <CaseStudyContent {...content} />
          <CaseStudyCarousel caseStudies={caseStudies} currentID={parseInt(id)} />
          <FormAndFooter />
        </>
      )
  }

  return <>{renderContent(content)}</>
}

export default CaseStudy
