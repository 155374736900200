export interface ValueProp {
  title: string
  description: string
}

export const forCreators: ValueProp[] = [
  {
    title: 'Seamless experience',
    description:
      'Satori’s end-to-end NFT toolkit handles minting and distribution in a familiar API, no Web3 coding skills required ',
  },
  {
    title: 'Accessible minting',
    description:
      'Low-cost, super-fast minting makes it easy to generate thousands of NFTs in a single drop and engage more fans',
  },
  {
    title: 'Customizable royalties',
    description:
      'Satori runs on NEAR Protocol, a carbon-neutral, Proof-of-Stake blockchain. Customize royalty splits right in the NFT contract & earn on all resales, anywhere',
  },
]

export const forDevs: ValueProp[] = [
  {
    title: 'Seamless experience',
    description:
      'Satori’s end-to-end NFT toolkit handles minting and distribution in a familiar API, no Web3 coding skills required ',
  },
  {
    title: 'Accessible minting',
    description:
      'Low-cost, super-fast minting makes it easy to generate thousands of NFTs in a single drop and engage more fans',
  },
  {
    title: 'Customizable royalties',
    description:
      'Satori runs on NEAR Protocol, a carbon-neutral, Proof-of-Stake blockchain. Customize royalty splits right in the NFT contract & earn on all resales, anywhere',
  },
]
