import styled from 'styled-components'
import ContactForm from '../ContactForm'
import Footer from '../Footer'
import bg from '../../assets/footer-blobs.png'
import FeedbackForm from '../FeedbackForm'

const BlobWrapper = styled.div`
  background: url(${bg}) no-repeat center center scroll;
  background-size: cover;
`

interface FormAndFooterProps {
  isFeedback?: boolean
}

function FormAndFooter({ isFeedback = false }: FormAndFooterProps) {
  return (
    <>
      <BlobWrapper>
        {isFeedback ? <FeedbackForm /> : <ContactForm />}
        <Footer />
      </BlobWrapper>
    </>
  )
}

export default FormAndFooter
