import { useState, useRef } from 'react'
import styled from 'styled-components'
import { Button1 } from './common/Button'
import { HF, ContentWrapper, Content } from './common/Layout'
import ProductDetails from './ProductDetails'
import ValueProps from './ValueProps'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import { Description } from './common/Typography'

const ChooseYourPath = styled(Content)`
  margin-bottom: 150px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 120px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-bottom: 80px;
  `};
`

const Heading = styled.h2`
  font-size: 64px;
  line-height: 100%;
  font-weight: normal;
  margin-bottom: 48px;

  text-align: center;
  color: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 56px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 40px;
    margin-bottom: 32px;
  `};
`

const Why = styled(Description)`
  margin-bottom: 48px;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-bottom: 32px
  `};
`

const ButtonGroup = styled(HF)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 4px;
  border-radius: 16px;

  .click-state {
    background-color: ${({ theme }) => theme.colors.white} !important;
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`

export default function CustomerSwitch() {
  const { upToExtraSmall } = useGetIsMobileScreenSize()

  const [isCreator, setIsCreator] = useState(true)
  const creator = useRef(null)
  const dev = useRef(null)

  function switchToDev(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    setIsCreator(false)
  }

  function switchToCreator(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    setIsCreator(true)
  }

  return (
    <ContentWrapper>
      <ChooseYourPath align={'center'}>
        <div>
          <Heading>Better Economics for Creators </Heading>
          <Why>
            NFTs can offer better economics for creators, their audiences, and the developers who build for them by
            removing rent-seeking intermediaries, enabling granular price tiering, and making users owners, thereby
            reducing customer acquisition costs to near zero.
          </Why>
        </div>
        <ButtonGroup>
          <Button1
            className={isCreator ? 'click-state' : ''}
            ref={creator}
            onClick={switchToCreator}
            minWidth={upToExtraSmall ? '150px' : '240px'}
          >
            I’m a Creator
            {upToExtraSmall ? null : ' / Brand'}
          </Button1>
          <Button1
            className={isCreator ? '' : 'click-state'}
            ref={dev}
            onClick={switchToDev}
            minWidth={upToExtraSmall ? '150px' : '240px'}
          >
            I'm a Developer
          </Button1>
        </ButtonGroup>
      </ChooseYourPath>

      <Content align={'center'} style={{ marginBottom: 64 }}>
        <ValueProps isCreator={isCreator} />
      </Content>

      <Content align={'center'} style={{ marginBottom: 64 }}>
        <ProductDetails isCreator={isCreator} />
      </Content>
    </ContentWrapper>
  )
}
