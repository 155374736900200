export interface CaseStudy {
  id: string
  title: string
  tagline: string
  imgURL: string
  externalURL: string
  summary: string[]
  descriptions: CaseStudyDesc[]
  metrics: [string, string][]
  productsUsed: string[]
}

export interface CaseStudyDesc {
  title: string
  content: Content[]
}

export interface Content {
  bold?: string
  body: string
  demoURL?: string
}

export const caseStudies: CaseStudy[] = [
  {
    id: '0',
    title: 'TikTok Drop with FNMeka’s 10 Million Followers',
    tagline: '1 million NFT drop via TikTok"',
    imgURL: '/fnmeka.png',
    externalURL: '',
    summary: [
      'Satori + FNMeka’s first collaboration unlocked the ability for creators to drop free NFTs to their fanbase through a Web2 social media platform: in this case, a million-NFT drop via TikTok.  FNMeka’s first drop to their 10 million+ followers focuses on education about the basics of crypto onboarding and NFTs––and thanks to Satori NFTs on NEAR, it was possible to drop them at zero cost to fans.',
      'FNMeka’s “Creator’s Pen” TikTok video series guides fans through a scavenger hunt game to guess Meka’s seed phrase and steal the prize. The Creator’s Pen grand prize NFT holder gets to create their own digital character with the makers of FNMeka.',
    ],
    descriptions: [
      {
        title: 'What we built',
        content: [
          {
            body: 'Satori built the portal for FNMeka’s Creator Pen game and the TikTok integration (which ensures that players have a corresponding account). The entire process for claiming an NFT once the fan verifies with TikTok is just 3 clicks: claim a NEAR wallet, claim the free NFT via claim link, and view the NFT in their wallet, which contains the clue to the game.',
            demoURL: 'https://youtu.be/VNyN3QQ0VLM',
          },
        ],
      },
    ],
    metrics: [],
    productsUsed: [],
  },
  {
    id: '1',
    title: 'Novel Fan Experiences',
    tagline: 'VR event activation drop',
    imgURL: '/illtown.png',
    externalURL: '',
    summary: [
      'Events are the ultimate experience for dedicated fans of any artist or brand. For the creator, Satori NFTs are a great way to reward their most loyal audience members who attend events and unlock more opportunities for them over time.',
    ],
    descriptions: [
      {
        title: 'What we built',
        content: [
          {
            bold: 'Illtown Sluggaz VR event drop',
            body: 'Hip-hop group Illtown Sluggaz hosted an event in Altspace VR and rewarded their attendees with a link to a free NFT. Fans who claimed their VR NFT got a code that allows token holders exclusive access to premium content in the Slugga Vault. ',
            demoURL: 'https://youtu.be/quel3h5HJBs',
          },
          {
            bold: 'Miami Art Basel drop',
            body: 'At 2021’s Art Basel event in Miami, the attendees of a gallery event hosted by SiSu DAO and NFTrees could redeem an NFT. By scanning a QR code in the physical space, partygoers received their free NFT on the spot via a claim link email. SiSu will engage token holders at future events as they build their community across the globe. ',
            demoURL: 'https://youtu.be/wXk5lBpjXzM',
          },
          {
            bold: 'House of Lobkowicz',
            body: 'The amazing House of Lobkowicz in Prague is using NFTs to make their priceless art collection accessible to a global audience and reimagine the experience of museum patronage for the next generation. At their Non-Fungible Castle conference and gala in October 2021, attendees could claim an NFT linked to their geolocation at Prague Castle. Over time, the museum can build a worldwide patron community mediated by NFTs.',
          },
        ],
      },
    ],
    metrics: [],
    productsUsed: [],
  },
  {
    id: '2',
    title: 'IRL Events',
    tagline: '"Live event community activation',
    imgURL: '/creatorpen.png',
    externalURL: '',
    summary: [
      'NFTs are already reshaping the world of ticketing, from verifying ownership and authenticity to putting more of the profit in the hands of artists, not resellers. An event ticket NFT is also a lasting keepsake for a fan and can be used to verify loyalty for future artist drops and activations.',
    ],
    descriptions: [
      {
        title: 'What we built',
        content: [
          {
            bold: 'Art Basel Satori Party',
            body: 'Satori created NFT “keys”to enter our exclusive party at Art Basel Miami 2021. In order to enter, the attendee needed to show their NFT––linked to their NEAR wallet confirming true ownership––to gain entry. Guests simply had to RSVP via email and claim their ticket for free.',
            demoURL: 'https://youtu.be/gZYwUEx3h9U',
          },
        ],
      },
    ],
    metrics: [],
    productsUsed: [],
  },
]
