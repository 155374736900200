import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { HF, VF, Content } from './common/Layout'
import { TitleSection, Description } from './common/Typography'
import { Button1 } from './common/Button'
import axios from 'axios'
import LoadingSpinner from './common/LoadingSpinner'

const Wrapper = styled.section`
  padding-top: 100px;
  padding-bottom: 250px;
  display: flex;
  flex-direction: column;
`
const Left = styled(VF)`
  flex: 1;
  margin-right: 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right:0;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-bottom: 48px;
  `};
`

const Right = styled(VF)`
  flex: 1;
  margin-left: 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-left: 0;
    align-items: center;
    justify-content: center;
  `};
`

const FormWrapper = styled(HF)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};
`

const FormFieldsContainer = styled(VF)`
  width: 100%;
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaWidth.upToMedium`    
    align-items: center;
    justify-content: center;
  `};
`

const FormField = styled(HF)`
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 506px;
  `};
`

const formStyles = css`
  padding: 24px;
  margin: 12px 0px;
  font-size: 20px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  font-family: ${({ theme }) => theme.fontFamily};
`
const Input = styled.input`
  ${formStyles}
  height: 64px;
`

const Textarea = styled.textarea`
  ${formStyles}
  resize: none;
  height: 320px;
  line-height: 1.25em;
`
const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const LabelSpan = styled.span`
  font-style: normal;
  font-size: 16px;
  line-height: 90%;
  color: ${({ theme }) => theme.colors.primary};
  opacity: 0.5;
`

const TS2 = styled(TitleSection)`
  margin-bottom: 8px;
  line-height: 100%;
  font-weight: normal;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    text-align: center;
  `};
`
const TS4 = styled(TitleSection)`
  margin-bottom: 8px;
  line-height: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    text-align: center;
  `};
`

const ButtonContainer = styled(HF)`
  width: 100%;
`

function FeedbackForm() {
  const GOOGLE_FORM_NAME_ID = process.env.REACT_APP_FEEDBACK_GOOGLE_FORM_NAME_ID as string
  const GOOGLE_FORM_EMAIL_ID = process.env.REACT_APP_FEEDBACK_GOOGLE_FORM_EMAIL_ID as string
  const GOOGLE_FORM_DESCRIPTION_ID = process.env.REACT_APP_FEEDBACK_GOOGLE_FORM_DESCRIPTION_ID as string
  const GOOGLE_FORM_ACTION_URL = process.env.REACT_APP_FEEDBACK_GOOGLE_FORM_ACTION_URL as string

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [description, setDescription] = useState('')

  const [isRequesting, setIsRequesting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState<string | null>(null)
  const formRef = useRef<HTMLFormElement>(null)

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setIsRequesting(true)

    const formData = new FormData()
    formData.append(GOOGLE_FORM_NAME_ID, name)
    formData.append(GOOGLE_FORM_EMAIL_ID, email)
    formData.append(GOOGLE_FORM_DESCRIPTION_ID, description)

    axios
      .post(GOOGLE_FORM_ACTION_URL, formData)
      .then(() => {
        setName('')
        setEmail('')
        setDescription('')
        if (formRef && formRef.current) {
          formRef.current.reset()
        }
        setIsRequesting(false)
        setIsSuccess(true)
      })
      .catch((e: any) => {
        setIsRequesting(false)
        console.log(e.message)

        // Assume it's a CORS error. The POST request still goes through.
        if (e.message === 'Network Error') {
          setIsSuccess(true)
        }

        setIsError(e.message)
      })
  }

  function handleReset() {
    setIsError(null)
  }

  return (
    <Wrapper id="contact">
      <Content align={'center'}>
        {isSuccess ? (
          <VF align="start" justify="center">
            <TS4 textAlign="center">Thank You!</TS4>
            <Description>We will get back to you as soon as possible.</Description>
          </VF>
        ) : isError ? (
          <VF align="start" justify="center">
            <TS4 textAlign="center">Uh Oh.</TS4>
            <Description>There was an error with your submission: {isError}</Description>
            <ButtonContainer align="center" justify="center">
              <Button1 onClick={handleReset}>Try Again</Button1>
            </ButtonContainer>
          </VF>
        ) : (
          <form ref={formRef} style={{ width: '100%' }} onSubmit={handleSubmit}>
            <FormWrapper>
              <Left>
                <TS2>Any complaints or feedback?</TS2>
                <TS4>Get in touch</TS4>
              </Left>
              <Right>
                <FormFieldsContainer flex={1}>
                  <FormField flex={1} className="hf">
                    <Label>
                      <LabelSpan>Full Name*</LabelSpan>
                      <Input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                    </Label>
                  </FormField>
                  <FormField flex={1}>
                    <Label>
                      <LabelSpan>Email*</LabelSpan>
                      <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </Label>
                  </FormField>
                  <FormField flex={1}>
                    <Label>
                      <LabelSpan>Description*</LabelSpan>
                      <Textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
                    </Label>
                  </FormField>
                  <FormField flex={1} margin={'32px 0 0'} justify="end">
                    {isRequesting ? <LoadingSpinner /> : <Button1 as="input" type="submit" value="Submit" />}
                  </FormField>
                </FormFieldsContainer>
              </Right>
            </FormWrapper>
          </form>
        )}
      </Content>
    </Wrapper>
  )
}

export default FeedbackForm
