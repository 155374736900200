import styled from 'styled-components'

const FlexBase = styled.div<{
  justify?: string
  align?: string
  margin?: string
  padding?: string
  width?: string
  height?: string
  flex?: number
  position?: string
  inset?: string
  shrink?: number
  wrap?: string
  grow?: string
  id?: string
}>`
  display: flex;
  justify-content: ${({ justify }) => justify ?? 'initial'};
  align-items: ${({ align }) => align ?? 'initial'};
  padding: ${({ padding }) => padding ?? '0px'};
  margin: ${({ margin }) => margin ?? '0px'};
  width: ${({ width }) => width ?? 'auto'};
  height: ${({ height }) => height ?? 'initial'};
  flex: ${({ flex }) => flex ?? 'initial'};
  ${({ position }) => {
    return position ? `position: ${position};` : ''
  }}
  ${({ inset }) => {
    return inset ? `inset: ${inset};` : ''
  }}
  ${({ shrink }) => {
    return shrink ? `flex-shrink: ${shrink};` : ''
  }}
  ${({ wrap }) => {
    return wrap ? `flex-wrap: ${wrap};` : ''
  }}
  ${({ grow }) => {
    return grow ? `flex-grow: ${grow};` : ''
  }}
`

export const HF = styled(FlexBase)<{
  direction?: string
}>`
  flex-direction: ${({ direction }) => direction ?? 'row'};
`

export const VF = styled(FlexBase)<{
  direction?: string
}>`
  flex-direction: ${({ direction }) => direction ?? 'column'};
`

export const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
`

export const Content = styled(VF)<{
  minHeight?: string
  marginLR?: string
}>`
  width: 100%;
  max-width: 1240px;
  margin-left: ${({ marginLR }) => marginLR ?? 'auto'};
  margin-right: ${({ marginLR }) => marginLR ?? 'auto'};
  min-height: ${({ minHeight }) => minHeight ?? 'auto'};

  /* border: 2px solid pink; */

  ${({ theme }) => theme.mediaWidth.upToLarge`
    max-width: unset;
    padding-left: 32px;
    padding-right: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    max-width: unset;
    padding-left: 24px;
    padding-right: 24px;
  `};
`

export const HeroWrapper = styled(VF)`
  align-items: center;
  background: transparent;

  padding-top: 220px;
  padding-bottom: 130px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top: 160px;
    padding-bottom: 100px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-top: 160px;
    padding-bottom: 80px;
  `};
`
