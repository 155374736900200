import styled from 'styled-components'
import { HeroWrapper, Content } from './common/Layout'
import { Title, Description } from './common/Typography'

const Tagline = styled(Title)`
  margin-bottom: 40px;
`

const SubTagline = styled(Description).attrs({
  as: 'h3',
})`
  max-width: 650px;
  font-size: 24px;
  line-height: 150%;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: .75em;
  `};
`

export default function Hero() {
  return (
    <HeroWrapper>
      <Content align={'center'}>
        <Tagline>Simple NFT Toolkit</Tagline>
        <div>
          <SubTagline>
            Satori makes it easy for artists and brands to mint thousands of NFTs and drop directly to fans – no coding
            or crypto required
          </SubTagline>
        </div>
      </Content>
    </HeroWrapper>
  )
}
