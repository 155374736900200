import styled from 'styled-components'
import { VF, HF, Content } from './common/Layout'
import { CaseStudy } from './content/casestudies'
import { DataLabel } from './common/Typography'
import { ChevronLeft, ChevronRight } from './icons/Chevron'
import { Link } from 'react-router-dom'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'

const Wrapper = styled(VF)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
  `};
`

const CarouselLinkWrapper = styled(HF)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
`

const CarouselLink = styled(Link)`
  text-decoration: none;
  background-color: ${({ theme }) => {
    return theme.colors.primary + theme.alpha['.1']
  }};
  border-radius: 16px;
  padding: 50px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary + theme.alpha['.05']};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 25px;
  `};
`
const NavTitle = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.primary};
`

const Container = styled(HF)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
  `};
`
interface CaseStudyCarouselProps {
  caseStudies: CaseStudy[]
  currentID: number
}

export default function CaseStudyCarousel({ caseStudies, currentID }: CaseStudyCarouselProps) {
  const { upToExtraSmall } = useGetIsMobileScreenSize()

  const lastID = caseStudies.length - 1
  const prevID = currentID !== 0 ? currentID - 1 : lastID
  const nextID = currentID !== lastID ? currentID + 1 : 0

  return (
    <Wrapper>
      <Content>
        <Container>
          <CarouselLinkWrapper flex={1} margin={upToExtraSmall ? '0 0 8px' : '0 12px 0 0'}>
            <CarouselLink to={`/case-studies/${prevID}`} style={{ width: '100%' }}>
              <HF
                justify="space-between"
                width="100%"
                direction={upToExtraSmall ? 'column' : 'row'}
                align={upToExtraSmall ? 'end' : 'start'}
              >
                <VF justify="center">
                  <ChevronLeft />
                </VF>
                <VF justify="center" align="end">
                  <DataLabel textAlign="right">{caseStudies[prevID].tagline}</DataLabel>
                  <NavTitle>{caseStudies[prevID].title}</NavTitle>
                </VF>
              </HF>
            </CarouselLink>
          </CarouselLinkWrapper>
          <CarouselLinkWrapper flex={1} margin={upToExtraSmall ? '0' : '0 0 0 12px'}>
            <CarouselLink to={`/case-studies/${nextID}`} style={{ width: '100%' }}>
              <HF
                justify="space-between"
                width="100%"
                direction={upToExtraSmall ? 'column-reverse' : 'row'}
                align={upToExtraSmall ? 'start' : 'start'}
              >
                <VF justify="center">
                  <DataLabel>{caseStudies[nextID].tagline}</DataLabel>
                  <NavTitle>{caseStudies[nextID].title}</NavTitle>
                </VF>
                <VF justify="center">
                  <ChevronRight />
                </VF>
              </HF>
            </CarouselLink>
          </CarouselLinkWrapper>
        </Container>
      </Content>
    </Wrapper>
  )
}
