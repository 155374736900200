import Metric from './Metric'

interface MetricsProps {
  metrics: [string, string][]
}

function Metrics({ metrics }: MetricsProps) {
  return (
    <>
      {metrics.map((metric) => (
        <Metric key={metric[0]} metric={metric}></Metric>
      ))}
    </>
  )
}

export default Metrics
