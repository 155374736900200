import styled from 'styled-components'
import { HF } from './common/Layout'
import ValueProp from './ValueProp'
import { forCreators, forDevs } from './content/valueprops'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'

export interface ValuePropsProps {
  isCreator: boolean
}

const Wrapper = styled(HF)`
  margin-bottom: 150px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 120px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-bottom: 80px;
  `};
`
function ValueProps({ isCreator }: ValuePropsProps) {
  const { upToExtraSmall } = useGetIsMobileScreenSize()
  const list = isCreator ? forCreators : forDevs

  return (
    <Wrapper direction={upToExtraSmall ? 'column' : 'row'}>
      {list.map((value) => (
        <ValueProp key={value.title} {...value}></ValueProp>
      ))}
    </Wrapper>
  )
}

export default ValueProps
