import { VF, HF, Content } from './common/Layout'
import CaseStudy from './CaseStudy'
import styled from 'styled-components'
import { TitleSection } from './common/Typography'
import { caseStudies } from './content/casestudies'

const Wrapper = styled(VF)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 120px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-bottom: 80px;
  `};
`

const ScrollableWrapper = styled(HF)`
  flex: 1;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex; 
    flex-wrap: nowrap; 
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; 
    -ms-overflow-style: -ms-autohiding-scrollbar; 
    width: 100%;
    
    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      flex: 0 0 auto;
    }
  `};
`

function CaseStudies() {
  return (
    <Wrapper>
      <Content align={'center'}>
        <TitleSection textAlign="center">Case Studies</TitleSection>
        <ScrollableWrapper>
          {caseStudies.map((value) => (
            <CaseStudy key={value.id} {...value}></CaseStudy>
          ))}
        </ScrollableWrapper>
      </Content>
    </Wrapper>
  )
}

export default CaseStudies
