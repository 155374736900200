import styled from 'styled-components'
import { VF } from './common/Layout'
import { Link } from 'react-router-dom'

export type CaseStudyProps = {
  id: string
  title: string
  tagline: string
  imgURL: string
}

const Wrapper = styled(VF)`
  margin-right: 64px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 416px;
    margin-right: 56px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    max-width: 270px;
    margin-right: 40px;
  `};

  &:last-of-type {
    margin-right: 0;
  }
`
const Image = styled.img`
  width: 100%;
`

const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  margin: 16px 0px;
  color: ${({ theme }) => theme.colors.primary};
`
const Subtitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;

  letter-spacing: 0.07em;
  text-transform: uppercase;

  opacity: 0.5;
  margin-top: 0;
  color: ${({ theme }) => theme.colors.primary};
`

function CaseStudy({ id, title, tagline, imgURL }: CaseStudyProps) {
  return (
    <Wrapper className="item">
      <Link to={`/case-studies/${id}`}>
        <VF>
          <Image src={imgURL} alt={title}></Image>
        </VF>
        <VF justify={'start'} align={'start'}>
          <Title>{title}</Title>
          <Subtitle>{tagline}</Subtitle>
        </VF>
      </Link>
    </Wrapper>
  )
}

export default CaseStudy
