import { VF } from './common/Layout'
import { Logo, Wordmark } from './logos/Satori'

export function BrandmarkVertical() {
  return (
    <VF align="center" justify="center">
      <div style={{ marginBottom: '40px' }}>
        <Logo />
      </div>
      <Wordmark />
    </VF>
  )
}
