import styled from 'styled-components'
import { BrandmarkVertical } from '../components/Brandmark'
import FormAndFooter from '../components/common/FormAndFooter'
import { HeroWrapper, ContentWrapper, Content, VF } from '../components/common/Layout'
import { Title, TitleSection, SubTitleSection, Description } from '../components/common/Typography'
import { InlineLink } from '../components/common/Link'

const Tagline = styled(Title)`
  margin-bottom: 40px;
`

const Bold = styled.span`
  font-weight: bold;
`

const Section = styled.section`
  margin-bottom: 40px;
`

const PPTitleSection = styled(TitleSection)`
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;

  margin-bottom: 24px;
`

const PPSubTitleSection = styled(SubTitleSection)`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;

  margin-bottom: 24px;
`

const ContentGrid = styled(Content)`
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
`

function PrivacyPolicy() {
  return (
    <>
      <HeroWrapper>
        <Content align={'center'}>
          <div style={{ marginBottom: 64 }}>
            <BrandmarkVertical />
          </div>
          <Tagline>Privacy Policy</Tagline>
        </Content>
      </HeroWrapper>
      <ContentWrapper>
        <ContentGrid align={'start'}>
          <VF />
          <VF>
            <Section>
              <Description>
                This privacy policy will explain how Satori Technologies Inc. and its affiliates (“Satori”, “we,” “us,”
                “our”) use information in operating our website, our apps, and other services that displays or provides
                an authorized link or reference to this Privacy Policy (collectively, the “Services”).
              </Description>
              <Description>
                If you do not agree to your information being used in the manner described in this Privacy Policy,
                please do not use the Services. If you have any questions about this Privacy Policy, please contact us
                at{' '}
                <InlineLink as="a" href="mailto: privacy@satori.art">
                  privacy@satori.art
                </InlineLink>{' '}
                (include "Privacy" in the subject line) or as described under Contact Us below.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>What data do we collect?</PPTitleSection>
              <Description>We collect and receive the following categories of information:</Description>
              <ul>
                <li>
                  <Description>
                    <Bold>Information You Provide Directly.</Bold> We receive the information you choose to provide when
                    using our Services, such as personal identification information (Name, email address, phone number,
                    etc.)
                  </Description>
                </li>
                <li>
                  <Description>
                    <Bold>Information We Collect or Receive When You Use Our Services.</Bold> We may also collect or
                    receive information when you use our Services. We collect some of this information using cookies,
                    web beacons, and other technologies.
                  </Description>
                </li>
                <li>
                  <Description>
                    <Bold>Location information.</Bold> We receive this information when you choose to use
                    location-enabled services that we offer, if any, and also when you use certain location-based
                    informational, marketing, or public service applications that we or other parties offer, if any.
                  </Description>
                </li>
              </ul>
            </Section>

            <Section>
              <PPTitleSection>How will we use your data?</PPTitleSection>
              <Description>
                We use each of the categories of information we collect and receive for the following business purposes:
              </Description>
              <ul>
                <li>
                  <Description>
                    <Bold>To provide our Services.</Bold> We use the information we collect and receive to provide you
                    with the Services you use or request. For example, we use this information to:
                  </Description>
                  <ul>
                    <li>
                      <Description>Create accounts and process your registration with our Services;</Description>
                    </li>
                    <li>
                      <Description>Provide technical support and respond to user inquiries;</Description>
                    </li>
                    <li>
                      <Description>
                        Notify you about updates to our Services or send other communications that are relevant to your
                        use of our Services; and
                      </Description>
                    </li>
                    <li>
                      <Description>
                        Enhance our ability to detect and prevent fraud and potentially illegal activities in connection
                        with our Services and otherwise enforce our Terms of Service.
                      </Description>
                    </li>
                  </ul>
                </li>
                <li>
                  <Description>
                    <Bold>To improve our operations and Services.</Bold> We also use the information we collect and
                    receive to provide content you may find relevant and interesting and to maintain and upgrade our
                    operations and our Services. For example, we may use this information to:
                  </Description>
                  <ul>
                    <li>
                      <Description>Solicit input and feedback about our Services;</Description>
                    </li>
                    <li>
                      <Description>Identify and address technical issues on our Services; and</Description>
                    </li>
                    <li>
                      <Description>
                        Better understand the visitors who come to our Services and the content of interest to them.
                      </Description>
                    </li>
                  </ul>
                </li>
              </ul>
              <Description>
                We also may use the information we collect and receive for other purposes that may be described to you
                at the point of collection or for which you provide your consent.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Security.</PPTitleSection>
              <Description>
                We have adopted physical, technical, and administrative safeguards to help protect against theft, loss,
                misuse, and unauthorized access to or disclosure of the information we collect and receive. However,
                please note that no data transmission or storage can be guaranteed to be 100% secure. As a result, while
                we strive to protect your information and privacy, we cannot and do not guarantee or warrant the
                security of any information you disclose or transmit to our Services and cannot be responsible for the
                theft, destruction, or inadvertent disclosure of your information, or any other disclosures out of our
                control.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Children's Privacy.</PPTitleSection>
              <Description>
                Our Services do not knowingly collect, use, or disclose personal information from children under the age
                of 13 or as otherwise defined by local law without prior parental consent, except as permitted by the
                Children's Online Privacy Protection Act ("COPPA") or other applicable local law.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Third Party Links.</PPTitleSection>
              <Description>
                Our Services may contain links to other sites that we do not own or operate. Also, links to our Services
                may be featured on third party services on which we advertise. We provide links to third party services
                as a convenience to our users. These links are not intended as an endorsement of or referral to the
                linked services. The linked services should have separate and independent privacy statements, notices,
                and terms of use, which we recommend you read carefully. We do not have any control over such services,
                and therefore we take no responsibility and assume no liability for the manner in which the entities
                that operate such linked services may collect, use, disclose, secure, or otherwise treat your
                information.
              </Description>
              <Description>
                Satori uses Plaid Inc. (“Plaid”) to gather your data from financial institutions. By using the Services,
                you grant Satori and Plaid the right, power, and authority to act on your behalf to access and transmit
                your personal and financial information from your relevant financial institution. You agree to your
                personal and financial information being transferred, stored, and processed by Plaid in accordance with
                the Plaid{' '}
                <InlineLink as="a" href="https://plaid.com/legal/#end-user-privacy-policy">
                  end user privacy policy
                </InlineLink>
                .
              </Description>
            </Section>

            <Section>
              <PPTitleSection>International Transfers of Personal Information</PPTitleSection>
              <Description>
                Satori’s headquarters is in the United States, and, regardless of where you use our Services or
                otherwise provide information to us, your information may be transferred to, maintained, and processed
                by Satori, its affiliates, and our operational partners and service providers in the United States.
                Please note that U.S. privacy laws, regulations, and standards may not be equivalent to the laws in your
                country of residence and such information may be subject to lawful access by U.S. courts, law
                enforcement, and governmental authorities. Please be aware that information we obtain as a result of
                your use of the Services may be collected, transferred, stored, and processed in the United States or
                another country in accordance with applicable law.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Legal Basis for Processing for EEA Residents</PPTitleSection>
              <Description>
                Satori makes no representations and warranties or any claims whatsoever in connection with compliance of
                the General Data Protection Regulation (GDPR). To the extent our activities are subject to international
                data protection laws that specify limited legal grounds to process your information, the legal bases for
                our processing activities are to perform our contract(s) with you; to meet our legal obligations; and
                for our legitimate business purposes, including to improve our operations and Services, detect and
                prevent fraud, and enforce our Terms of Service. We rely on your consent to send you direct marketing
                messages about our products, and services. If you do not provide certain information to us, we may, in
                some circumstances, be unable to comply with our obligations or provide you with the services that you
                request. We will tell you about the implications of not providing certain information.
              </Description>
              <Description>
                We retain personal information for only as long as we need it to provide the Services, for purposes
                described in this Privacy Policy, for our own administration and internal business purposes, and for any
                periods that are mandated by law, after which, we take steps to delete or anonymize information.We
                retain personal information for only as long as we need it to provide the Services, for purposes
                described in this Privacy Policy, for our own administration and internal business purposes, and for any
                periods that are mandated by law, after which, we take steps to delete or anonymize information.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Changes To This Privacy Policy.</PPTitleSection>
              <Description>
                We may update this Privacy Policy from time to time to reflect changes in our privacy practices, so we
                encourage you to review this Privacy Policy periodically. If we make significant changes to this Privacy
                Policy, we will provide appropriate notice to you.
              </Description>
            </Section>

            <Section>
              {' '}
              <PPTitleSection>Contact Us.</PPTitleSection>
              <Description>
                If you have any questions about this Privacy Policy or the manner in which we or our service providers
                treat personal information, please contact us at{' '}
                <InlineLink as="a" href="mailto: privacy@satori.art">
                  privacy@satori.art
                </InlineLink>{' '}
                and select "Privacy" for the subject line.
              </Description>
            </Section>

            <Section>
              <PPTitleSection>Cookies, Web Beacons, and Other Technologies.</PPTitleSection>
              <Description>
                Our Services use online technologies called "cookies" and "web beacons," as well as other local storage
                technologies. This section explains what these technologies are and how they may be used.
              </Description>
              <PPSubTitleSection>Cookies and Other Local Storage Technologies</PPSubTitleSection>
              <Description>
                Generally speaking, "cookies" are text files that are placed in your device's browser, and that can be
                used to help recognize your browser across different web pages, websites, and browsing sessions. Your
                browser or device may offer settings related to cookies. For example, you may be able to configure your
                browser not to accept cookies or to notify you when a cookie is being sent. For more information about
                whether these settings are available, what they do, and how they work, visit your browser or device's
                help material. If you decide not to accept cookies from us, you may not be able to take advantage of all
                of the features of the Services.
              </Description>
              <Description>
                Cookies are stored on your device or in "local storage." We also use other types of local storage
                technologies, such as Local Shared Objects (sometimes called "Flash cookies"), in connection with our
                Services. These technologies are similar to cookies in that they are stored on your device and can be
                used to maintain information about your activities and preferences. However, these other local storage
                technologies may use parts of your device other than your browser, which means you may not be able to
                control their use using the browser tools and settings you use to control browser cookies. For more
                information about managing Flash cookies, please visit the Adobe Flash Player website. Your browser's
                privacy controls may enable you to manage other types of local storage.
              </Description>
              <Description>
                Our Services may use cookies or other local storage technologies in combination with other information
                about you to enhance and personalize your experience on our Services (or elsewhere online), including:
              </Description>
              <ul>
                <li>
                  <Description>to help authenticate you when you use our Services;</Description>
                </li>
                <li>
                  <Description>to remember your preferences and registration information;</Description>
                </li>
                <li>
                  <Description>
                    to present and help measure and research the effectiveness of our Service, advertisements, and email
                    communications (by determining which emails you open and act upon); and
                  </Description>
                </li>
                <li>
                  <Description>
                    to customize the content and advertisements provided to you through our Services and on other
                    websites and services you visit.{' '}
                  </Description>
                </li>
              </ul>
            </Section>
          </VF>
          <VF />
        </ContentGrid>
      </ContentWrapper>
      <FormAndFooter isFeedback />
    </>
  )
}

export default PrivacyPolicy
