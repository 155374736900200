export function reverseLayout(i: number) {
  return i % 2 !== 0 ? true : false
}

export function setFlexRowDirection(value: boolean) {
  return value ? 'row-reverse' : 'reverse'
}

export function setFlexColumnDirection(value: boolean) {
  return value ? 'column-reverse' : 'column'
}

export function setLRMargin(value: boolean, pixels: number) {
  return value ? `0 0 0 ${pixels}px` : `0 ${pixels}px 0 0`
}

export function setTBMargin(value: boolean, pixels: number) {
  return value ? `0 0 ${pixels}px 0` : `${pixels}px 0 0 0`
}
