import styled from 'styled-components'
import { HeroWrapper, Content } from './common/Layout'
import { Title, Description } from './common/Typography'
import { VF } from './common/Layout'
import bg from '../assets/cs-header-bg.png'
import CaseStudyDescription from './CaseStudyDescription'
import Metrics from './Metrics'
import ProductsUsed from './ProductsUsed'
import { CaseStudy } from './content/casestudies'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'

const CaseStudyContentWrapper = styled(HeroWrapper)`
  background: url(${bg}) no-repeat center center scroll;
  background-size: cover;
  padding-bottom: 0;

  margin-bottom: 100px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 80px;
  `};
`

const HeroContent = styled(Content)`
  display: grid;
  grid-template-columns: 6fr 1fr 5fr;
  grid-gap: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
  `};
`
const HeroTitle = styled(Title)`
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 100%;
`
const Subtitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;

  letter-spacing: 0.07em;
  text-transform: uppercase;

  opacity: 0.5;
  margin-bottom: 16px;
`

const Image = styled.img`
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 40px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-bottom: 32px;
  `};
`

const Summary = styled(VF)`
  border-bottom: 1px solid rgba(16, 17, 80, 0.2);
`

interface CaseStudyContentProps extends CaseStudy {}

export default function CaseStudyContent({
  id,
  title,
  tagline,
  imgURL,
  externalURL,
  summary,
  descriptions,
  metrics,
  productsUsed,
}: CaseStudyContentProps) {
  const { upToSmall, upToExtraSmall } = useGetIsMobileScreenSize()

  return (
    <CaseStudyContentWrapper>
      <HeroContent>
        <VF>
          <Summary margin={upToExtraSmall ? '0 0 40px' : '0 0 60px'} padding={upToExtraSmall ? '0 0 16px' : '0 0 36px'}>
            <Subtitle>{tagline}</Subtitle>
            <HeroTitle>{title}</HeroTitle>
            {upToSmall ? (
              <VF>
                <div>
                  <Image src={imgURL} alt={title}></Image>
                </div>
              </VF>
            ) : null}
            {summary.map((item, i) => (
              <Description key={i}>{item}</Description>
            ))}
          </Summary>
          <CaseStudyDescription descriptions={descriptions} />
        </VF>
        {upToSmall ? null : <VF></VF>}
        {upToSmall ? null : (
          <VF>
            <div>
              <Image src={imgURL} alt={title}></Image>
            </div>
            <VF justify={'start'} align={'start'} flex={1}>
              <ProductsUsed products={productsUsed} />
              <Metrics metrics={metrics} />
            </VF>
          </VF>
        )}
      </HeroContent>
    </CaseStudyContentWrapper>
  )
}
