import styled from 'styled-components'
import { HF, VF } from './common/Layout'
import { Description } from './common/Typography'

export type ValuePropProps = {
  title: string
  description: string
}

const Wrapper = styled(HF)`
  margin: 0 52px 0 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 0 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `};
`

const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 32px;
  `};
`

function ValueProp({ title, description }: ValuePropProps) {
  return (
    <Wrapper flex={1}>
      <VF justify={'start'} align={'start'}>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </VF>
    </Wrapper>
  )
}

export default ValueProp
