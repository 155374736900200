import { useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import './App.css'
import Landing from './pages/Landing'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Navbar from './components/Navbar'
import CaseStudy from './pages/CaseStudy'
import ScrollToTop from './components/ScrollToTop'
import TermsOfUse from './pages/TermsOfUse'
import { pageView } from './services/googleAnalytics'

export default function App() {
  const location = useLocation()

  useEffect(() => {
    pageView(location.pathname)
  }, [location])

  return (
    <div className="App">
      <Navbar className={'nav'} />
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/terms-of-use">
          <TermsOfUse />
        </Route>
        <Route path="/case-studies/:id" children={<CaseStudy />} />
      </Switch>
    </div>
  )
}
