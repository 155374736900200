import styled from 'styled-components'
import { Button1, Button1ExternalLink, Button2ExternalLink } from './common/Button'
import { Description } from './common/Typography'
import { HashLink } from 'react-router-hash-link'

interface LinkProps {
  label?: string
  margin?: string
}

const Notification = styled(Description)`
  font-weight: bold;
`

export function ContactLink({ label = 'Contact Us', margin = '' }: LinkProps) {
  return (
    <HashLink smooth to="/#contact">
      <Button1 margin={margin}>{label}</Button1>
    </HashLink>
  )
}

export function DocsLink({ label = 'Read The Docs', margin = '' }: LinkProps) {
  return (
    <Button2ExternalLink margin={margin} href="https://docs.satori.art/docs/intro/">
      {label}
    </Button2ExternalLink>
  )
}

export function ShopDropLink() {
  return <Notification>Coming Soon</Notification>
}

export function StudioLink({ label = 'Start Creating', margin = '' }: LinkProps) {
  return (
    <Button1ExternalLink margin={margin} href="https://studio.satori.art/">
      {label}
    </Button1ExternalLink>
  )
}
